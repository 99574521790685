import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// Local
import { RouteEnum } from '@/configs/RouteEnum'
import { getInitials } from '@/helpers/string'
import { authActions, authSelectors } from '@/bus/auth'
import { RootState } from '@/core/store/rootReducer'
import { urlToList } from '@/helpers/pathTools'
import { productionsActions, productionsSelectors } from '@/bus/productions'

const selectStore = (state: RootState) => ({
  account: authSelectors.getCurrentAccount(state),
  isAdmin: authSelectors.isAdmin(state),
  isPartner: authSelectors.isPartner(state),
})

export const useHandlers = () => {
  const history = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()
  const { isAdmin, isPartner, account } = useSelector(selectStore)
  const statistics = useSelector(productionsSelectors.getProductionsStatistics)

  const [historySelect, setHistorySelect] = useState('0')

  const navigate = {
    0: RouteEnum.App,
    1: RouteEnum.Clients,
    2: RouteEnum.Partners,
    3: RouteEnum.Productions,
    4: RouteEnum.Stock,
    5: RouteEnum.AlignersStatistics,
    6: RouteEnum.PaymentsMenu,
    7: RouteEnum.InteractionNotifications,
    10: RouteEnum.Orders,
  }

  useEffect(() => {
    if (location.pathname) {
      const urlList = urlToList(location.pathname)
      const route = urlList[1] ? urlList[1] : RouteEnum.App

      for (let item in navigate) {
        if (route === navigate[item]) {
          setHistorySelect(item)
        }
      }
    }
  }, [location.pathname, navigate])

  useEffect(() => {
    dispatch(productionsActions.fetchStatisticsAsync())
  }, [])

  const handleClick = (e) => {
    const link = navigate[e.key]
    history.push(link)
    setHistorySelect(e.key)
  }

  const initial = useMemo(() => {
    if (account?.info?.firstName && account?.info?.lastName) {
      return getInitials(`${account?.info.firstName} ${account.info.lastName}`)
    }
  }, [account])

  const handleLogout = () => {
    dispatch(authActions.logoutAsync())
  }

  const goToSetting = () => {
    history.push(RouteEnum.AccountSettings)
  }

  return {
    isAdmin,
    isPartner,
    account,
    historySelect,
    initial,
    statistics,
    handleClick,
    handleLogout,
    goToSetting,
  }
}
