import React from 'react'
import { Link } from 'react-router-dom'
// Local
import { RouteEnum } from '@/configs/RouteEnum'
import { FullScreen, Logo } from '@/components'
// Local Dir
import styles from './styles.module.scss'
import ReactPlayer from 'react-player'

type PropsTypes = {
  children: React.ReactNode
}

export const AuthLayout: React.FC<PropsTypes> = ({ children }: PropsTypes) => {
  return (
    <FullScreen>
      <div className={styles.root}>
        <aside className={styles.aside}>
          <div className={styles.aside__content}>
            <div className={styles.aside__logo}>
              <Link to={RouteEnum.App}>
                <Logo />
              </Link>
            </div>
            {children}
          </div>
        </aside>
        <div className={styles.preview}>
          <h2 className="title">
            Вітаємо Вас у партнерській програмі Impress.
          </h2>
          <div className={styles.preview__video}>
            <ReactPlayer
              url="/static/preview.mp4"
              playing={true}
              width={875}
              height={460}
              playsinline
              muted
              style={{ maxWidth: '100%' }}
            />
          </div>
        </div>
      </div>
    </FullScreen>
  )
}
