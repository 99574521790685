import React from 'react'
import { Layout, Menu } from 'antd'
import clsx from 'clsx'
import {
  PieChartOutlined,
  CreditCardOutlined,
  HomeOutlined,
  BellOutlined,
} from '@ant-design/icons'
// Local
import { RouteEnum } from '@/configs/RouteEnum'
import { useHandlers } from './useHandlers'
import {
  BriefcaseOutline,
  FileOutline,
  PeopleOutline,
  StockOutline,
} from '@/assets/icons'
import styles from './styles.module.scss'
import { useInteractiveNotificationsCount } from './use-interactive-notifications-count.hook'
import { useOrdersCount } from './use-orders-count.hook'

type PropsTypes = {
  collapsed: boolean
}

export const AsideMenu: React.FC<PropsTypes> = ({ collapsed }) => {
  const { isPartner, isAdmin, historySelect, handleClick, statistics } =
    useHandlers()

  const { count: interactiveNotificationsCount } =
    useInteractiveNotificationsCount()

  const { count: ordersCount } = useOrdersCount()

  const _renderMenuLink = () => {
    const data = [
      {
        id: '4',
        href: RouteEnum.FAQ,
        title: 'Питання/Відповіді',
        icon: FileOutline,
      },
    ]
    return (
      <Menu selectable={false}>
        {data.map((item) => {
          const Icon = item.icon
          return (
            <Menu.Item key={item.id} className={styles.link}>
              <span>
                <Icon className={styles.link__icon} />
                <a
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  <span style={{ paddingLeft: 10 }}>{item.title}</span>
                </a>
              </span>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  return (
    <Layout.Sider
      trigger={null}
      theme="light"
      collapsible
      className={`${styles.root} ${collapsed ? styles.fadeIn : styles.feydOut}`}
      width={240}
    >
      <div className={styles.root__wrapper}>
        <div className={styles.root__content}>
          <Menu selectedKeys={[historySelect]} onClick={handleClick}>
            <Menu.Item key="1" className={styles.link}>
              <PeopleOutline />
              <span>Клієнти</span>
            </Menu.Item>
            {isAdmin && (
              <>
                <Menu.Item key="2" className={styles.link}>
                  <BriefcaseOutline />
                  <span>Партнери</span>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  className={styles.link}
                  itemIcon={
                    <div
                      className={clsx(
                        styles.iconProduction,
                        historySelect === '3' && styles.iconActive
                      )}
                    >
                      {statistics.created}
                    </div>
                  }
                >
                  <HomeOutlined />
                  <span>Виробництво</span>
                </Menu.Item>
              </>
            )}

            {isAdmin && (
              <Menu.Item
                key="7"
                className={styles.link}
                itemIcon={
                  <div
                    className={clsx(
                      styles.iconProduction,
                      historySelect === '7' && styles.iconActive
                    )}
                  >
                    {interactiveNotificationsCount}
                  </div>
                }
              >
                <BellOutlined />
                <span>Сповіщення</span>
              </Menu.Item>
            )}

            {isAdmin && (
              <Menu.Item
                key="10"
                className={styles.link}
                itemIcon={
                  <div
                    className={clsx(
                      styles.iconProduction,
                      historySelect === '10' && styles.iconActive
                    )}
                  >
                    {ordersCount}
                  </div>
                }
              >
                <BellOutlined />
                <span>Замовлення</span>
              </Menu.Item>
            )}

            {isAdmin && !isPartner && (
              <Menu.Item key="4" className={styles.link}>
                <StockOutline />
                <span>Склад</span>
              </Menu.Item>
            )}
            {isAdmin && (
              <>
                <Menu.Item key="5" className={styles.link}>
                  <PieChartOutlined />
                  <span>Статистика</span>
                </Menu.Item>
                <Menu.Item key="6" className={styles.link}>
                  <CreditCardOutlined />
                  <span>Оплати</span>
                </Menu.Item>
              </>
            )}
          </Menu>
          {_renderMenuLink()}
        </div>
      </div>
    </Layout.Sider>
  )
}
